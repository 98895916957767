import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from "../Contexts/LoginContextProvider";
import {useNavigate} from "react-router-dom";

const SPAWN_INTERVAL = 750; // Frequent spawns: one every 1.5 seconds

function Login({title}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const {login} = useContext(LoginContext);
    const [tokenInput, setTokenInput] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const {loginState} = useContext(LoginContext);

    const [image, setImage] = useState(false);
    const [imageUrl, setImageUrl] = useState("https://media.tenor.com/rCjuyc-i63MAAAAj/among-us.gif");

    const [crewmates, setCrewmates] = useState([]);

    const handleLogin = async () => {
        if (!tokenInput.trim()) {
            alert("⚠️ Please enter a valid token!");
            return;
        }

        setImage(true);
        setImageUrl("https://media.tenor.com/rCjuyc-i63MAAAAj/among-us.gif");

        setTimeout(async () => {
            try {
                const response = await fetch('https://v2.whustle.com/login', {
                    method: 'POST',
                    headers: {
                        'Authorization': tokenInput,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 403) {
                    setImageUrl("https://static.wikia.nocookie.net/54381750-eaed-4eb3-aee1-f65e1dc8c576?timestamp=" + Date.now());
                    setError("🚫 Invalid token. Please try again.");
                    return;
                }

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const data = await response.json();
                const { web, plausible, store_id } = data;

                setError(null);
                login(tokenInput, web, plausible, store_id);
            } catch (err) {
                console.error("Login failed:", err);
                setImageUrl("https://static.wikia.nocookie.net/54381750-eaed-4eb3-aee1-f65e1dc8c576?timestamp=" + Date.now());
                setError("😢 Something went wrong. Please try again.");
            }
        }, 1200);
    };

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn) {
            navigate("/dashboard");
        }
    }, [loginState, navigate]);

    // Spawn logic for crewmates with random paths
    useEffect(() => {
        const spawnCrewmate = () => {
            const id = Date.now();

            // Determine start edge and end edge
            // Edges: top, bottom, left, right
            const edges = ['top', 'bottom', 'left', 'right'];

            let startEdge = edges[Math.floor(Math.random() * edges.length)];
            let endEdge = edges[Math.floor(Math.random() * edges.length)];
            // Ensure endEdge is not the same as startEdge
            while (endEdge === startEdge) {
                endEdge = edges[Math.floor(Math.random() * edges.length)];
            }

            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

            // Random positions along the chosen edges
            const randomXStart = Math.random() * vw;
            const randomYStart = Math.random() * vh;
            const randomXEnd = Math.random() * vw;
            const randomYEnd = Math.random() * vh;

            let startX = 0;
            let startY = 0;
            let endX = 0;
            let endY = 0;

            // Assign start coordinates based on startEdge
            if (startEdge === 'top') {
                startX = randomXStart;
                startY = -100;
            } else if (startEdge === 'bottom') {
                startX = randomXStart;
                startY = vh + 100;
            } else if (startEdge === 'left') {
                startX = -100;
                startY = randomYStart;
            } else if (startEdge === 'right') {
                startX = vw + 100;
                startY = randomYStart;
            }

            // Assign end coordinates based on endEdge
            if (endEdge === 'top') {
                endX = randomXEnd;
                endY = -100;
            } else if (endEdge === 'bottom') {
                endX = randomXEnd;
                endY = vh + 100;
            } else if (endEdge === 'left') {
                endX = -100;
                endY = randomYEnd;
            } else if (endEdge === 'right') {
                endX = vw + 100;
                endY = randomYEnd;
            }

            // Calculate dx, dy
            const dx = endX - startX;
            const dy = endY - startY;

            const newCrewmate = {
                id,
                startX,
                startY,
                dx,
                dy
            };

            setCrewmates((prev) => [...prev, newCrewmate]);
        };

        const interval = setInterval(spawnCrewmate, SPAWN_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    const handleCrewmateAnimationEnd = (id) => {
        setCrewmates((prev) => prev.filter((c) => c.id !== id));
    };

    return (
        <>
            <style>{`
                @keyframes gradientAnimation {
                    0% {
                        background-position: 0% 50%;
                    }
                    50% {
                        background-position: 100% 50%;
                    }
                    100% {
                        background-position: 0% 50%;
                    }
                }

                @keyframes flyAcross {
                    0% { transform: translate(0px, 0px) rotate(0deg); }
                    100% { transform: translate(var(--dx), var(--dy)) rotate(1080deg); }
                }

                .flying-crewmate {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    pointer-events: none; 
                    animation: flyAcross 10s linear forwards;
                }
            `}</style>
            <div
                className="min-h-screen flex items-center justify-center px-4 relative overflow-hidden"
                style={{
                    background: "linear-gradient(-45deg, #1f2937, #111827, #1f2937, #111827)",
                    backgroundSize: "400% 400%",
                    animation: "gradientAnimation 15s ease infinite",
                }}
            >
                {/* Render spawned crewmates */}
                {crewmates.map((crewmate) => (
                    <img
                        key={crewmate.id}
                        src="https://preview.redd.it/an871k4o1sn51.png?width=440&format=png&auto=webp&s=85dcd6cb73b8760802e254ee14dfa3c7ab444591"
                        className="flying-crewmate"
                        style={{
                            top: crewmate.startY,
                            left: crewmate.startX,
                            '--dx': `${crewmate.dx}px`,
                            '--dy': `${crewmate.dy}px`,
                        }}
                        alt=""
                        onAnimationEnd={() => handleCrewmateAnimationEnd(crewmate.id)}
                    />
                ))}

                <div className="bg-gray-900 rounded-lg shadow-2xl p-8 max-w-md w-full transform transition duration-500 hover:scale-105 hover:shadow-[0_0_40px_rgba(0,0,0,0.8)] ease-in-out relative z-10">
                    <h1 className="text-4xl font-extrabold text-gray-100 mb-6 text-center">
                        Welcome Back!
                    </h1>
                    <p className="text-gray-300 text-center mb-6 text-lg">
                        Please enter your token to access the admin panel
                    </p>
                    <div className="mb-4">
                        <input
                            type="text"
                            placeholder="🔑 Enter token..."
                            value={tokenInput}
                            onChange={(e) => setTokenInput(e.target.value)}
                            className="w-full border border-gray-700 bg-gray-800 text-gray-100 rounded-lg shadow-sm focus:ring-purple-500 focus:border-purple-500 p-3"
                        />
                    </div>
                    <button
                        onClick={handleLogin}
                        className={`w-full bg-purple-600 hover:bg-purple-700 text-white font-bold rounded-lg px-4 py-3 transition duration-300 focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                            !tokenInput.trim() ? "opacity-70 cursor-not-allowed" : ""
                        }`}
                        disabled={!tokenInput.trim()}
                    >
                        🚀 Log In
                    </button>
                    {image && (
                        <div className="w-full flex justify-center items-center mt-4">
                            <img className="rounded-lg w-32 h-32 object-cover border-4 border-gray-800" src={imageUrl} alt="Status Animation"/>
                        </div>
                    )}
                    {error && (
                        <p className="mt-4 text-red-400 text-sm text-center font-semibold">
                            {error}
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default Login;
