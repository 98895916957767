// AdditionalImages.jsx
import React, { useRef, useState, useEffect } from 'react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FaGripVertical } from 'react-icons/fa'; // Import the drag handle icon

// SortableImage Component
const SortableImage = ({ image, onDelete }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: image.id.toString() });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.8 : 1,
        cursor: isDragging ? 'grabbing' : 'grab',
        position: 'relative',
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="relative bg-gray-700 p-2 rounded-lg"
        >
            {/* Drag Handle */}
            <div
                {...attributes}
                {...listeners}
                className="absolute top-2 left-2 cursor-grab"
                aria-label="Drag Handle"
                role="button"
                tabIndex={0}
            >
                <FaGripVertical className="text-gray-300" />
            </div>

            {/* Image */}
            <img
                src={image.url}
                alt="Product"
                className="w-20 h-20 object-cover rounded-lg"
            />

            {/* Delete Button */}
            <button
                onClick={() => onDelete(image.id)}
                className="absolute top-0 right-0 bg-red-600 text-white text-sm rounded-full p-1 focus:outline-none"
                draggable="false"
                aria-label="Delete image"
            >
                ×
            </button>
        </div>
    );
};

// AdditionalImages Component
const AdditionalImages = ({ productId, token }) => {
    const fileInputRef = useRef(null);
    const [product, setProduct] = useState({
        images: [],
        // ... other product properties if needed
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
    const [orderError, setOrderError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    // Initialize sensors for DnD Kit
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    // Fetch product data on component mount
    useEffect(() => {
        const fetchProduct = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`https://v2.whustle.com/product/${productId}`, {
                    headers: {
                        Authorization: token,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Error fetching product: ${response.statusText}`);
                }
                const data = await response.json();
                console.log('Fetched product data:', data);
                if (!Array.isArray(data.images)) {
                    throw new Error('Invalid product data format.');
                }

                // Check if images have 'order' field
                let sortedImages;
                if (data.images[0]?.order !== undefined) {
                    sortedImages = data.images.sort((a, b) => a.order - b.order);
                } else {
                    // If 'order' is not present, assign it based on index
                    sortedImages = data.images.map((img, index) => ({
                        ...img,
                        order: index,
                    }));
                }

                setProduct({ ...data, images: sortedImages });
            } catch (error) {
                console.error('Error fetching product:', error);
                setOrderError('Failed to load images. Please try again.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProduct();
    }, [productId, token]);

    // Handle drag end event
    const handleDragEnd = async (event) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = product.images.findIndex((img) => img.id.toString() === active.id);
            const newIndex = product.images.findIndex((img) => img.id.toString() === over.id);

            if (oldIndex === -1 || newIndex === -1) {
                console.error('Invalid drag indices.');
                return;
            }

            const reorderedImages = arrayMove(product.images, oldIndex, newIndex);

            // Assign new order based on the new array
            const imagesWithOrder = reorderedImages.map((img, index) => ({
                id: img.id,
                order: index,
            }));

            // Optimistically update the UI
            setProduct((prev) => ({
                ...prev,
                images: reorderedImages,
            }));

            // Set updating state
            setIsUpdatingOrder(true);
            setOrderError(null);
            setSuccessMessage(null);

            try {
                const response = await fetch(
                    `https://v2.whustle.com/products/${productId}/images/order`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: token,
                        },
                        body: JSON.stringify({ images: imagesWithOrder }),
                    }
                );

                if (!response.ok) {
                    throw new Error(`Server responded with ${response.status}`);
                }

                const data = await response.json();
                console.log('Image order updated:', data);
                setSuccessMessage('Image order updated successfully!');
            } catch (error) {
                console.error('Error updating image order:', error);
                setOrderError('Failed to update image order. Reverting changes.');

                // Revert to the previous order if there's an error
                const originalImages = arrayMove(reorderedImages, newIndex, oldIndex);
                setProduct((prev) => ({
                    ...prev,
                    images: originalImages,
                }));
            } finally {
                setIsUpdatingOrder(false);
            }
        }
    };

    // Handle image deletion
    const handleDeleteImage = async (imageId) => {
        try {
            const response = await fetch(
                `https://v2.whustle.com/products/images/${imageId}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: token,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}`);
            }

            const data = await response.json();
            console.log('Image deleted:', data);

            // Remove image from state
            setProduct((prev) => ({
                ...prev,
                images: prev.images.filter((img) => img.id !== imageId),
            }));
        } catch (error) {
            console.error('Error deleting image:', error);
            alert('Failed to delete image. Please try again.');
        }
    };

    // Handle image upload
    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch(`https://v2.whustle.com/products/${productId}/upload-image`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: token,
                },
            });

            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}`);
            }

            const data = await response.json();
            setProduct((prev) => ({
                ...prev,
                images: [...prev.images, { id: data.imageId, url: data.img_url, order: prev.images.length }],
            }));
            fileInputRef.current.value = '';
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Failed to upload image. Please try again.');
        }
    };

    return (
        <div className="bg-gray-800 rounded-lg shadow p-6 mt-8">
            <h2 className="text-lg font-semibold text-gray-100 mb-4">Additional Images</h2>

            {isLoading ? (
                <div className="text-gray-300">Loading images...</div>
            ) : (
                <>
                    {isUpdatingOrder && (
                        <div className="mb-4 text-yellow-400">Updating image order...</div>
                    )}
                    {orderError && (
                        <div className="mb-4 text-red-400">{orderError}</div>
                    )}
                    {successMessage && (
                        <div className="mb-4 text-green-400">{successMessage}</div>
                    )}

                    <DndContext
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        sensors={sensors}
                    >
                        <SortableContext
                            items={product.images.map((img) => img.id.toString())}
                            strategy={rectSortingStrategy} // Adjusted strategy for grid layout
                        >
                            <div className="flex flex-wrap gap-4">
                                {product.images.map((image) => (
                                    <SortableImage
                                        key={image.id}
                                        image={image}
                                        onDelete={handleDeleteImage}
                                    />
                                ))}
                            </div>
                        </SortableContext>
                    </DndContext>

                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageUpload}
                        className="block mt-4 text-gray-300"
                        disabled={isUpdatingOrder}
                    />
                </>
            )}
        </div>
    );
};

export default AdditionalImages;
