import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Navbar from "../Components/Navbar";
import {LoginContext} from "../Contexts/LoginContextProvider";

function CreateCollection({title}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const [form, setForm] = useState({
        title: "",
        description: "",
        handle: "",
        storeid: 2, // Default store ID
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm((prev) => ({...prev, [name]: value}));
    };
    const { getToken, loginState } = useContext(LoginContext);
    const token = getToken(); // Retrieve the token from context

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("https://v2.whustle.com/collections", {
            method: "POST",
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form),
        })
            .then(() => navigate("/collectionsList"))
            .catch((error) => console.error("Error creating collection:", error));
    };

    return (
        <div className="flex md:flex-row flex-col bg-gray-900 text-gray-100 min-h-screen overflow-hidden">
            <Navbar/>
            <div className="p-4 md:p-8 h-screen mx-auto md:w-2/3 w-full">
                <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-100">
                    Create Collection
                </h1>
                <form
                    onSubmit={handleSubmit}
                    className="bg-gray-800 rounded-lg shadow p-6 space-y-6"
                >
                    {/* Title */}
                    <div>
                        <label
                            className="block text-gray-300 font-medium mb-2"
                            htmlFor="title"
                        >
                            Title
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={form.title}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>

                    {/* Description */}
                    <div>
                        <label
                            className="block text-gray-300 font-medium mb-2"
                            htmlFor="description"
                        >
                            Description
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            value={form.description}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            rows="4"
                        ></textarea>
                    </div>

                    {/* Handle */}
                    <div>
                        <label
                            className="block text-gray-300 font-medium mb-2"
                            htmlFor="handle"
                        >
                            Handle
                        </label>
                        <input
                            type="text"
                            id="handle"
                            name="handle"
                            value={form.handle}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full bg-green-600 hover:bg-green-700 text-white font-medium rounded-lg px-4 py-2"
                    >
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default CreateCollection;
