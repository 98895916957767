import React, {useContext, useEffect} from "react";
import {NavLink, Outlet, useNavigate} from "react-router-dom";
import {FaProductHunt, FaTags, FaImages, FaChartBar, FaHome} from "react-icons/fa";
import Navbar from "../Components/Navbar";
import PlausibleEmbed from "../Components/PlausibleEmbed";
import {LoginContext} from "../Contexts/LoginContextProvider";

function Dashboard({title}) {
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        document.title = `${title} - Admin Panel`;

        // Clean up (optional)
        return () => {
            document.title = "Admin Panel"; // Reset to default when the component unmounts
        };
    }, [title]); // Depend on title to update the title when the product changes


    useEffect(() => {
        // Dynamically load the Plausible Analytics script
        const script = document.createElement("script");
        script.src = "https://plausible.whustle.com/js/embed.host.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Clean up script if component is unmounted
            document.body.removeChild(script);
        };
    }, []);

    const navigate = useNavigate();
    const { loginState } = useContext(LoginContext);
    // Redirect to login if not logged in
    useEffect(() => {
        console.log(loginState);
        if (loginState.initialized && !loginState.loggedIn) {
            navigate("/login");
        }
    }, [loginState, navigate]);


    return (
        <>
            <div className="flex  md:flex-row flex-col h-screen bg-[#1a202c] ">
                <Navbar/>
                <PlausibleEmbed/>
            </div>
        </>
    );
}

export default Dashboard;
