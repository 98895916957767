import React, { useState, useEffect, useContext } from "react";
import Navbar from "../Components/Navbar";
import { LoginContext } from "../Contexts/LoginContextProvider";

function CustomerList({ title }) {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const [customers, setCustomers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const { loginState, getToken } = useContext(LoginContext);
    const token = getToken();

    const customersPerPage = 10;

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchCustomers();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    const fetchCustomers = () => {
        setLoading(true);
        fetch(`https://v2.whustle.com/customers/${loginState.store_id}`, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => setCustomers(data))
            .catch((error) => console.error("Error fetching customers:", error))
            .finally(() => setLoading(false));
    };

    const filteredCustomers = customers.filter((customer) => {
        const query = searchQuery.toLowerCase();
        return (
            (customer.name || "").toLowerCase().includes(query) ||
            (customer.email || "").toLowerCase().includes(query) ||
            (customer.phone || "").toLowerCase().includes(query)
        );
    });

    // Pagination logic
    const indexOfLastCustomer = currentPage * customersPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
    const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);
    const totalPages = Math.ceil(filteredCustomers.length / customersPerPage);

    const goToNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const goToPreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    return (
        <div className="flex md:flex-row flex-col w-full bg-gray-900 text-gray-100 min-h-screen overflow-hidden">
            {loading && (
                <div className="fixed flex justify-center items-center w-full h-screen bg-black bg-opacity-60 z-50 top-0 left-0">
                    <img
                        src="https://media.tenor.com/DYYUoqO0GJ0AAAAj/among-us-walking.gif"
                        alt="Loading"
                    />
                </div>
            )}
            <Navbar />
            <div className="p-4 md:p-8 overflow-y-auto h-screen md:w-2/3 mx-auto">
                <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-100">👥 Customers</h1>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="🔍 Search by Name, Email, or Phone"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="border border-gray-700 p-2 rounded-lg w-full mb-4 bg-gray-800 text-gray-100 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    />
                </div>

                <div className="overflow-x-auto relative shadow-md sm:rounded-lg bg-gray-800">
                    <table className="w-full text-sm text-left text-gray-200">
                        <thead className="text-xs uppercase bg-gray-700 text-gray-300">
                        <tr>
                            <th className="px-6 py-3">Name</th>
                            <th className="px-6 py-3">Email</th>
                            <th className="px-6 py-3">Phone</th>
                            <th className="px-6 py-3">Address</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentCustomers.map((customer) => (
                            <tr
                                key={customer.id}
                                className="border-b border-gray-700 hover:bg-gray-700"
                            >
                                <td className="px-6 py-4 font-medium text-gray-100">{customer.name || "-"}</td>
                                <td className="px-6 py-4">{customer.email || "-"}</td>
                                <td className="px-6 py-4">{customer.phone || "-"}</td>
                                <td className="px-6 py-4">
                                    {customer.address
                                        ? `${customer.address.line1 || ""}, ${customer.address.city || ""}, ${customer.address.country || ""}`
                                        : "-"}
                                </td>
                            </tr>
                        ))}
                        {currentCustomers.length === 0 && (
                            <tr>
                                <td colSpan="4" className="px-6 py-4 text-gray-300 text-center">
                                    No customers found.
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                {filteredCustomers.length > 0 && (
                    <div className="flex justify-between items-center mt-4">
                        <button
                            onClick={goToPreviousPage}
                            disabled={currentPage === 1}
                            className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                currentPage === 1
                                    ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 text-white hover:bg-blue-700"
                            }`}
                        >
                            ← Previous
                        </button>
                        <span className="text-gray-300 font-medium">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={goToNextPage}
                            disabled={currentPage === totalPages}
                            className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                currentPage === totalPages
                                    ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 text-white hover:bg-blue-700"
                            }`}
                        >
                            Next →
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CustomerList;
