import React, {useState, useEffect, useContext} from "react";
import {useParams, useNavigate} from "react-router-dom";
import Navbar from "../Components/Navbar";
import {LoginContext} from "../Contexts/LoginContextProvider";

function EditCollection({title}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;

        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const {id} = useParams();
    const [form, setForm] = useState({title: "", description: "", handle: ""});
    const navigate = useNavigate();

    const {loginState, getToken} = useContext(LoginContext);
    const token = getToken();

    const fetchData = () => {
        fetch(`https://v2.whustle.com/collections/${id}`, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if(data.title && data.title.length > 0){
                    document.title = `${data.title} - Admin Panel`;
                }
                setForm(data);
            })
            .catch((error) => console.error("Error fetching collection:", error));
    };

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchData();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm((prev) => ({...prev, [name]: value}));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(`https://v2.whustle.com/collections/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'Authorization': token
            },
            body: JSON.stringify(form),
        })
            .then(() => navigate("/collectionsList"))
            .catch((error) => console.error("Error updating collection:", error));
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-gray-100 min-h-screen overflow-hidden">
            <Navbar/>
            <div className="p-4 md:p-8 mx-auto md:w-2/3 w-full">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-6">
                    Edit Collection
                </h1>
                <form
                    onSubmit={handleSubmit}
                    className="bg-gray-800 rounded-lg shadow p-6 space-y-6"
                >
                    {/* Title */}
                    <div>
                        <label
                            className="block text-gray-300 font-medium mb-2"
                            htmlFor="title"
                        >
                            Title
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={form.title}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>

                    {/* Description */}
                    <div>
                        <label
                            className="block text-gray-300 font-medium mb-2"
                            htmlFor="description"
                        >
                            Description
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            value={form.description}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            rows="4"
                        ></textarea>
                    </div>

                    {/* Handle */}
                    <div>
                        <label
                            className="block text-gray-300 font-medium mb-2"
                            htmlFor="handle"
                        >
                            Handle
                        </label>
                        <input
                            type="text"
                            id="handle"
                            name="handle"
                            value={form.handle}
                            onChange={handleChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="flex md:flex-row flex-col md:inline-flex justify-between w-full md:w-2/6 space-y-4 md:space-y-0">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg px-4 py-2"
                        >
                            Update
                        </button>
                        <a
                            href={`https://${loginState.web}/collections/${form.handle}`}
                            target="_blank"
                            className="md:ml-4 w-full text-center bg-gray-600 hover:bg-gray-700 text-white font-medium rounded-lg px-4 py-2"
                            rel="noreferrer"
                        >
                            View in store
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditCollection;
