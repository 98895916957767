import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie"; // Install js-cookie: npm install js-cookie

// Create the context
export const LoginContext = createContext();

// LoginContextProvider Component
export const LoginContextProvider = ({ children }) => {
    const [loginState, setLoginState] = useState({
        store_id: -1,
        loggedIn: false,
        token: "",
        web: "",
        plausible: "",
        initialized: false, // Indicates if login state is initialized
    });

    // Initialize login state from cookies
    useEffect(() => {
        const storeId = Cookies.get("store_id");
        const token = Cookies.get("authToken");
        const web = Cookies.get("web");
        const plausible = Cookies.get("plausible");
        setLoginState({
            store_id: storeId || -1,
            loggedIn: !!token, // True if token exists
            token: token || "",
            web: web || "",
            plausible: plausible || "",
            initialized: true, // Set to true after initialization
        });
    }, []);

    // Login function
    const login = (token, web, plausible, storeId) => {
        Cookies.set("authToken", token, { expires: 7 }); // Set cookie with a 7-day expiry
        Cookies.set("web", web, { expires: 7 }); // Set cookie with a 7-day expiry
        Cookies.set("plausible", plausible, { expires: 7 }); // Set cookie with a 7-day expiry
        Cookies.set("store_id", storeId, { expires: 7 }); // Set cookie with a 7-day expiry
        setLoginState({ loggedIn: true, token, web, plausible, store_id: storeId, initialized: true });
    };

    // Logout function
    const logout = () => {
        Cookies.remove("authToken"); // Remove the cookie
        Cookies.remove("web"); // Remove the cookie
        Cookies.remove("plausible"); // Remove the cookie
        Cookies.remove("store_id"); // Remove the cookie
        setLoginState({ loggedIn: false, store_id: -1, token: "", web: "", plausible: "", initialized: true });
    };

    // Function to get the current token
    const getToken = () => (loginState.initialized ? loginState.token : null);
    const getWeb = () => (loginState.initialized ? loginState.web : null);

    return (
        <LoginContext.Provider value={{ loginState, login, logout, getWeb, getToken }}>
            {children}
        </LoginContext.Provider>
    );
};
