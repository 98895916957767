import React, { createContext, useState } from "react";

export const PaginationContext = createContext();

export const PaginationProvider = ({ children }) => {
    const [paginationState, setPaginationState] = useState({});

    const savePaginationState = (key, state) => {
        setPaginationState((prev) => ({
            ...prev,
            [key]: state,
        }));
    };

    const getPaginationState = (key) => {
        return paginationState[key] || { currentPage: 1, filter: "" };
    };

    return (
        <PaginationContext.Provider value={{ savePaginationState, getPaginationState }}>
            {children}
        </PaginationContext.Provider>
    );
};
