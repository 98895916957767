import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { LoginContext } from "../Contexts/LoginContextProvider";
import { PaginationContext } from "../Contexts/PaginationContext";

function CollectionsList({ title }) {
    const [collections, setCollections] = useState([]);
    const [filterQuery, setFilterQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const collectionsPerPage = 7; // Number of collections per page
    const navigate = useNavigate();

    const { loginState, getToken } = useContext(LoginContext);
    const { savePaginationState, getPaginationState } = useContext(PaginationContext);
    const token = getToken();

    // Restore saved state on mount
    useEffect(() => {
        const savedState = getPaginationState("collections");
        setFilterQuery(savedState.filter || "");
        setCurrentPage(savedState.currentPage || 1);
    }, [getPaginationState]);

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchCollections();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    const fetchCollections = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://v2.whustle.com/collections`, {
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            setCollections(data);
        } catch (error) {
            console.error("Error fetching collections:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (e) => {
        const newFilter = e.target.value;
        setFilterQuery(newFilter);
        setCurrentPage(1); // Reset to the first page when filter changes
        savePaginationState("collections", { currentPage: 1, filter: newFilter });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        savePaginationState("collections", { currentPage: newPage, filter: filterQuery });
    };

    const handleNavigate = (path) => {
        savePaginationState("collections", { currentPage, filter: filterQuery });
        navigate(path);
    };

    const deleteCollection = (id) => {
        if (window.confirm("Are you sure you want to delete this collection?")) {
            fetch(`https://v2.whustle.com/collections/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            })
                .then(() => setCollections((prev) => prev.filter((collection) => collection.id !== id)))
                .catch((error) => console.error("Error deleting collection:", error));
        }
    };

    // Filter collections locally based on the filter query
    const filteredCollections = collections.filter((collection) =>
        collection.title.toLowerCase().includes(filterQuery.toLowerCase())
    );

    // Pagination logic
    const indexOfLastCollection = currentPage * collectionsPerPage;
    const indexOfFirstCollection = indexOfLastCollection - collectionsPerPage;
    const currentCollections = filteredCollections.slice(indexOfFirstCollection, indexOfLastCollection);
    const totalPages = Math.ceil(filteredCollections.length / collectionsPerPage);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel"; // Reset to default on unmount
        };
    }, [title]);

    return (
        <div className="flex md:flex-row flex-col w-full bg-gray-900 text-gray-100 min-h-screen overflow-hidden">
            {loading && (
                <div className="fixed flex justify-center items-center w-full h-screen bg-black bg-opacity-60 z-50 top-0 left-0">
                    <img
                        src="https://media.tenor.com/DYYUoqO0GJ0AAAAj/among-us-walking.gif"
                        alt="Loading"
                    />
                </div>
            )}
            <Navbar />
            <div className="p-4 md:p-8 h-screen md:w-2/3 mx-auto">
                <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-100">Collections</h1>

                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Search Collections"
                        value={filterQuery}
                        onChange={handleFilterChange}
                        className="border border-gray-700 p-2 rounded-lg w-full mb-4 bg-gray-800 text-gray-100 placeholder-gray-400"
                    />
                </div>

                <button
                    onClick={() => handleNavigate("/collectionsEdit/create")}
                    className="mb-6 text-white bg-green-600 hover:bg-green-700 font-medium rounded-lg text-sm px-4 py-2"
                >
                    Add Collection
                </button>

                <div className="overflow-x-auto relative shadow-md sm:rounded-lg bg-gray-800">
                    <table className="w-full text-sm text-left text-gray-200">
                        <thead className="text-xs uppercase bg-gray-700 text-gray-300">
                        <tr>
                            <th className="px-6 py-3">Title</th>
                            <th className="px-6 py-3">Handle</th>
                            <th className="px-6 py-3">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentCollections.map((collection) => (
                            <tr key={collection.id} className="border-b border-gray-700 hover:bg-gray-700">
                                <td className="px-6 py-4 font-medium text-gray-100">{collection.title}</td>
                                <td className="px-6 py-4 text-gray-100">{collection.handle}</td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() => handleNavigate(`/collectionsEdit/edit/${collection.id}`)}
                                        className="text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2 mr-2"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => deleteCollection(collection.id)}
                                        className="text-white bg-red-600 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {currentCollections.length === 0 && (
                            <tr>
                                <td colSpan="3" className="px-6 py-4 text-gray-300 text-center">
                                    No collections found.
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                {filteredCollections.length > 0 && (
                    <div className="flex justify-between items-center mt-4">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                currentPage === 1
                                    ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 text-white hover:bg-blue-700"
                            }`}
                        >
                            Previous
                        </button>
                        <span className="text-gray-300 font-medium">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className={`px-4 py-2 font-medium text-sm rounded-lg ${
                                currentPage === totalPages
                                    ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 text-white hover:bg-blue-700"
                            }`}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CollectionsList;
