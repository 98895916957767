import React, {useContext, useEffect, useState} from 'react';
import {LoginContext} from "../Contexts/LoginContextProvider";

const PlausibleEmbed = () => {


    const { loginState } = useContext(LoginContext);

    const [plausible, setPlausible] = useState({});
    const [web, setWeb] = useState({});

    const loadData =  () => {
        // Dynamicky načtení skriptu
        const script = document.createElement('script');
        script.src = 'https://plausible.whustle.com/js/embed.host.js';
        script.async = true;
        document.body.appendChild(script);
        setPlausible(loginState.plausible);
        setWeb(loginState.web);
        return () => {
            // Odstranění skriptu při odpojení komponenty
            document.body.removeChild(script);
        };
    }

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn) {
            loadData();
        }
    }, [loginState.initialized, loginState.loggedIn]);


    return (
        <div className="overflow-y-auto md:mt-0 mt-8 p-8 h-screen w-full mx-auto h-screen bg-[#1a202c]">
            <iframe
                src={"https://plausible.whustle.com/share/" + web + "?auth=" + plausible + "&embed=true&theme=dark"}
                scrolling="no"
                frameBorder="0"
                loading="lazy"
                className={`h-[1900px] w-full`}
            ></iframe>
        </div>
    );
};

export default PlausibleEmbed;
