import React, {useState, useEffect, useContext} from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import Navbar from "../Components/Navbar";
import {LoginContext} from "../Contexts/LoginContextProvider";
import {FaExclamationTriangle} from "react-icons/fa";

// Helper functions for Prague time conversion
function getPragueLocalDateString(date) {
    // date is a Date object
    // Convert to Prague time and get dd/mm/yyyy, HH:MM:SS
    const pragueString = date.toLocaleString("en-GB", {timeZone: "Europe/Prague"});
    // Typically returns something like "dd/mm/yyyy, HH:MM:SS"
    const [datePart] = pragueString.split(", ");
    const [day, month, year] = datePart.split("/");
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

function getPragueLocalHour(date) {
    // Convert to Prague time
    const pragueString = date.toLocaleString("en-GB", {timeZone: "Europe/Prague"});
    // pragueString: "dd/mm/yyyy, HH:MM:SS"
    const [, timePart] = pragueString.split(", ");
    const [hour] = timePart.split(":");
    return hour.padStart(2, '0');
}

function getPragueLocalYearMonth(date) {
    const pragueDate = getPragueLocalDateString(date); // "YYYY-MM-DD"
    const [y, m] = pragueDate.split("-");
    return `${y}-${parseInt(m)}`; // "YYYY-M"
}

function OrderStats({title}) {
    const [isDebilOpen, setIsDebilOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const [orders, setOrders] = useState([]);
    const [timeFrame, setTimeFrame] = useState("today");
    const [stats, setStats] = useState({totalOrders: 0, totalValue: 0, avgOrdersPerDay: 0});
    const [selectedStats, setSelectedStats] = useState({totalOrders: 0, totalValue: 0});
    const [chartData, setChartData] = useState([]);
    const [viewMode, setViewMode] = useState("czk");
    const [loading, setLoading] = useState(true);

    // Tabs: Current Stats and Past Data
    const [mainTab, setMainTab] = useState("current"); // 'current' or 'history'
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const {loginState, getToken} = useContext(LoginContext);
    const token = getToken();

    // Preselect date range when switching to history
    useEffect(() => {
        if (mainTab === "history") {
            const now = new Date();
            const currentYear = now.getFullYear();
            const firstDayOfYear = `${currentYear}-01-01`;
            const lastDayOfYear = `${currentYear}-12-31`;
            setStartDate(firstDayOfYear);
            setEndDate(lastDayOfYear);
        }
    }, [mainTab]);

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchOrders();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    // For current tab
    useEffect(() => {
        if (orders.length && mainTab === "current") {
            const data = getChartDataForCurrent(timeFrame);
            setChartData(data);
            calculateSelectedStats(data);
        }
    }, [orders, timeFrame, mainTab]);

    const [gigachad, setGigaChad] = useState("");

    useEffect(() => {
        if (loginState.initialized && loginState.loggedIn && token) {
            fetchGiga();
        }
    }, [loginState.initialized, loginState.loggedIn, token]);

    const fetchGiga = () => {
        setLoading(true);
        fetch(`https://api.whustle.com/gigachad`, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                switch (data.position) {
                    case 1:
                        setGigaChad(<span>🏆
                        <span
                            className="bg-gradient-to-r from-yellow-400 via-red-500 to-orange-500 text-transparent bg-clip-text">
                První
            </span>   </span>
                        );
                        break;
                    case 2:
                        setGigaChad(<span>🥈
                            <span
                                className="bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 text-transparent bg-clip-text">
                Druhý
            </span>   </span>
                        );
                        break;
                    case 3:
                        setGigaChad(<span>🥉
                            <span
                                className="bg-gradient-to-r from-orange-400 via-yellow-500 to-red-500 text-transparent bg-clip-text">
                 Třetí
            </span></span>
                        );
                        break;
                    default:
                        setGigaChad(
                            data.position ? (
                                `#${data.position}`
                            ) : (
                                <span className="text-red-500">Neumístěn</span>
                            )
                        );
                        break;
                }
            });
    };


    // For past data tab
    useEffect(() => {
        if (orders.length && mainTab === "history" && startDate && endDate) {
            const data = getChartDataForHistory(startDate, endDate);
            setChartData(data);
            calculateSelectedStats(data);
        }
    }, [orders, startDate, endDate, viewMode, mainTab]);

    const fetchOrders = () => {
        setLoading(true);
        fetch(`https://v2.whustle.com/orders/${loginState.store_id}`, {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setOrders(data.orders);
                calculateStats(data.orders);
            })
            .catch((error) => console.error("Error fetching orders:", error))
            .finally(() => setLoading(false));
    };

    const calculateStats = (orders) => {
        const totalOrders = orders.length;
        const totalValue = orders.reduce((sum, order) => sum + order.price, 0);
        const days = new Set(orders.map(order => getPragueLocalDateString(new Date(order.created_at)))).size;
        const avgOrdersPerDay = days > 0 ? (totalOrders / days).toFixed(2) : 0;
        setStats({totalOrders, totalValue, avgOrdersPerDay});
    };

    const calculateSelectedStats = (data) => {
        const totalOrders = data.reduce((sum, d) => sum + d.orders, 0);
        const totalValue = data.reduce((sum, d) => sum + d.czk, 0);
        setSelectedStats({totalOrders, totalValue});
    };

    const getFullRangeData = (dataMap, unitList) => {
        return unitList.map((key) => {
            const entry = dataMap[key] || {czk: 0, orders: 0};
            return {date: key, czk: entry.czk, orders: entry.orders};
        });
    };


    // Chart Data for Current Stats
    const getChartDataForCurrent = (timeFrame) => {
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth();

        let filteredOrders = [...orders];

        const yearsInOrders = orders.map(o => {
            const d = new Date(o.created_at);
            return d.getFullYear();
        });
        const minYear = Math.min(...yearsInOrders);
        const maxYear = Math.max(...yearsInOrders);

        if (timeFrame === "today") {
            const todayStr = getPragueLocalDateString(now);
            filteredOrders = filteredOrders.filter(o => {
                const pragueDateStr = getPragueLocalDateString(new Date(o.created_at));
                return pragueDateStr === todayStr;
            });

            const hourly = {};
            filteredOrders.forEach((o) => {
                const hour = getPragueLocalHour(new Date(o.created_at));
                if (!hourly[hour]) hourly[hour] = {czk: 0, orders: 0};
                hourly[hour].czk += o.price;
                hourly[hour].orders += 1;
            });

            const hours = Array.from({length: 24}, (_, i) => String(i).padStart(2, '0'));
            return getFullRangeData(hourly, hours);

        } else if (timeFrame === "yesterday") {
            const yesterday = new Date(now);
            yesterday.setDate(now.getDate() - 1);
            const yesterdayStr = getPragueLocalDateString(yesterday);

            filteredOrders = filteredOrders.filter(o => {
                const pragueDateStr = getPragueLocalDateString(new Date(o.created_at));
                return pragueDateStr === yesterdayStr;
            });

            const hourly = {};
            filteredOrders.forEach((o) => {
                const hour = getPragueLocalHour(new Date(o.created_at));
                if (!hourly[hour]) hourly[hour] = {czk: 0, orders: 0};
                hourly[hour].czk += o.price;
                hourly[hour].orders += 1;
            });

            const hours = Array.from({length: 24}, (_, i) => String(i).padStart(2, '0'));
            return getFullRangeData(hourly, hours);

        } else if (timeFrame === "thisMonth") {
            // Filter by current month in Prague time
            filteredOrders = filteredOrders.filter(o => {
                const orderDate = new Date(o.created_at);
                const y = parseInt(orderDate.toLocaleString("en-GB", {timeZone: "Europe/Prague", year: 'numeric'}));
                const m = parseInt(orderDate.toLocaleString("en-GB", {
                    timeZone: "Europe/Prague",
                    month: 'numeric'
                })) - 1;
                return y === currentYear && m === currentMonth;
            });

            const daily = {};
            filteredOrders.forEach((o) => {
                const pragueDateStr = getPragueLocalDateString(new Date(o.created_at));
                if (!daily[pragueDateStr]) daily[pragueDateStr] = {czk: 0, orders: 0};
                daily[pragueDateStr].czk += o.price;
                daily[pragueDateStr].orders += 1;
            });

            const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate();
            const yearMonthStr = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}`;
            const days = Array.from({length: lastDay}, (_, i) => {
                const day = i + 1;
                return `${yearMonthStr}-${String(day).padStart(2, '0')}`;
            });
            return getFullRangeData(daily, days);

        } else if (timeFrame === "thisYear") {
            // Filter by current year in Prague time
            filteredOrders = filteredOrders.filter(o => {
                const d = new Date(o.created_at);
                const y = parseInt(d.toLocaleString("en-GB", {timeZone: "Europe/Prague", year: 'numeric'}));
                return y === currentYear;
            });

            const monthly = {};
            filteredOrders.forEach((o) => {
                const d = new Date(o.created_at);
                const monthKey = getPragueLocalYearMonth(d); // "YYYY-M"
                if (!monthly[monthKey]) monthly[monthKey] = {czk: 0, orders: 0};
                monthly[monthKey].czk += o.price;
                monthly[monthKey].orders += 1;
            });

            const months = Array.from({length: 12}, (_, i) => `${currentYear}-${i + 1}`);
            return getFullRangeData(monthly, months);

        } else if (timeFrame === "allTime") {
            const yearly = {};
            filteredOrders.forEach((o) => {
                const d = new Date(o.created_at);
                const y = parseInt(d.toLocaleString("en-GB", {timeZone: "Europe/Prague", year: 'numeric'}));
                const yearKey = y.toString();
                if (!yearly[yearKey]) yearly[yearKey] = {czk: 0, orders: 0};
                yearly[yearKey].czk += o.price;
                yearly[yearKey].orders += 1;
            });

            const years = Array.from({length: (maxYear - minYear + 1)}, (_, i) => String(minYear + i));
            return getFullRangeData(yearly, years);
        }

        return [];
    };

    // Chart Data for Past Data
    const getChartDataForHistory = (startDate, endDate) => {
        if (!startDate || !endDate) return [];
        const start = new Date(startDate);
        const end = new Date(endDate);

        let filteredOrders = orders.filter(o => {
            const d = new Date(o.created_at);
            return d >= start && d <= end;
        });

        const diffDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
        if (diffDays <= 31) {
            const daily = {};
            filteredOrders.forEach((o) => {
                const pragueDateStr = getPragueLocalDateString(new Date(o.created_at));
                if (!daily[pragueDateStr]) daily[pragueDateStr] = {czk: 0, orders: 0};
                daily[pragueDateStr].czk += o.price;
                daily[pragueDateStr].orders += 1;
            });

            const rangeDays = [];
            for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
                const ds = getPragueLocalDateString(new Date(d));
                rangeDays.push(ds);
            }
            return getFullRangeData(daily, rangeDays);

        } else {
            const monthly = {};
            filteredOrders.forEach((o) => {
                const monthKey = getPragueLocalYearMonth(new Date(o.created_at));
                if (!monthly[monthKey]) monthly[monthKey] = {czk: 0, orders: 0};
                monthly[monthKey].czk += o.price;
                monthly[monthKey].orders += 1;
            });

            const monthRange = [];
            const d = new Date(start);
            d.setDate(1);
            const endM = new Date(end);
            endM.setDate(1);

            while (d <= endM) {
                const key = `${d.getFullYear()}-${d.getMonth() + 1}`;
                monthRange.push(key);
                d.setMonth(d.getMonth() + 1);
            }

            return getFullRangeData(monthly, monthRange);
        }
    };

    const getOrdersLabel = () => {
        if (mainTab === "history") {
            return "🛍️ Orders (Selected Range)";
        }

        switch (timeFrame) {
            case "today":
                return "🛍️ Orders Today";
            case "yesterday":
                return "🛍️ Orders Yesterday";
            case "thisMonth":
                return "🗓️ Orders This Month";
            case "thisYear":
                return "📆 Orders This Year";
            case "allTime":
                return "🌎 Total Orders (All Time)";
            default:
                return "Orders";
        }
    };

    const getSalesLabel = () => {
        if (mainTab === "history") {
            return "💰 Sales (Selected Range)";
        }

        switch (timeFrame) {
            case "today":
                return "💰 Sales Today";
            case "yesterday":
                return "💰 Sales Yesterday";
            case "thisMonth":
                return "💰 Sales This Month";
            case "thisYear":
                return "💰 Sales This Year";
            case "allTime":
                return "💰 Total Sales (All Time)";
            default:
                return "Sales";
        }
    };

    const getOverviewTitle = () => {
        if (mainTab === "history") {
            return "📈 Past Data Overview";
        }

        switch (timeFrame) {
            case "today":
                return "📈 Today's Overview";
            case "yesterday":
                return "📈 Yesterday's Overview";
            case "thisMonth":
                return "📈 This Month's Overview";
            case "thisYear":
                return "📈 This Year's Overview";
            case "allTime":
                return "📈 All Time Overview";
            default:
                return "📈 Overview";
        }
    };

    return (
        <div className="flex md:flex-row flex-col w-full bg-gray-900 text-gray-100 min-h-screen">
            {loading && (
                <div
                    className="fixed flex justify-center items-center w-full h-screen bg-black bg-opacity-60 z-50 top-0 left-0">
                    <img
                        src="https://media.tenor.com/DYYUoqO0GJ0AAAAj/among-us-walking.gif"
                        alt="Loading"
                    />
                </div>
            )}
            <Navbar/>
            <div className="p-4 md:p-8 overflow-y-auto h-screen md:w-2/3 w-full mx-auto">
                <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-white">
                    📊 Orders and Earnings
                </h1>

                <div className="mb-4 md:mb-6">
                    <div
                        className="flex flex-col justify-between items-center align-center p-4 rounded-lg bg-gray-800 space-y-4 md:space-y-0">
                        <div className="text-center">
                            <h2 className="text-base md:text-lg font-semibold text-gray-100">
                                Pozice v GigaChadovi
                            </h2>
                            <p
                                className={`text-xl md:text-2xl inline-flex font-bold`}>
                                {gigachad}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Main Tabs */}
                <div className="mb-2 md:mb-4 flex space-x-2 md:space-x-4">
                    <button
                        onClick={() => setMainTab("current")}
                        className={`py-1 px-2 md:py-2 md:px-4 rounded text-sm ${mainTab === "current" ? "bg-blue-600 text-white" : "bg-gray-700 text-gray-200"}`}
                    >
                        Current Stats
                    </button>
                    <button
                        onClick={() => setMainTab("history")}
                        className={`py-1 px-2 md:py-2 md:px-4 rounded text-sm ${mainTab === "history" ? "bg-blue-600 text-white" : "bg-gray-700 text-gray-200"}`}
                    >
                        Past Data
                    </button>
                </div>

                <div className="mb-4 md:mb-6">
                    <div
                        className="flex flex-col md:flex-row justify-between items-center p-4 rounded-lg bg-gray-800 space-y-4 md:space-y-0">
                        <div className="text-center">
                            <h2 className="text-base md:text-lg font-semibold text-gray-100">
                                {getOrdersLabel()}
                            </h2>
                            <p className={`text-xl md:text-2xl font-bold text-[#9f7aea]`}>{selectedStats.totalOrders}</p>
                        </div>
                        <div className="text-center">
                            <h2 className="text-base md:text-lg font-semibold text-gray-100">
                                {getSalesLabel()}
                            </h2>
                            <p
                                onMouseEnter={() => {
                                    if (selectedStats.totalValue !== 0) return;
                                    setIsDebilOpen(true);
                                }}
                                onMouseLeave={() => {
                                    if (selectedStats.totalValue !== 0) return;
                                    setIsDebilOpen(false);
                                }}
                                className={`text-xl md:text-2xl inline-flex font-bold  ${selectedStats.totalValue === 0 ? "text-red-500" : "text-[#48bb78]"}`}>{selectedStats.totalValue !== 0 ? "" :
                                <div
                                    className={"relative"}
                                    data-tip="Nečum a běž makat"
                                >
                                    <FaExclamationTriangle
                                        className={"mr-2 mt-1"}/>
                                    {isDebilOpen && <div
                                        className={"w-64 absolute md:top-8 top-6 md:left-[-60px] left-[-75px] rounded-b-md pt-1 p-4 text-sm text-red-400 bg-gray-800 flex items-center justify-center"}>
                                        ❗Nečum a běž makat❗
                                    </div>}
                                </div>} {selectedStats.totalValue.toFixed(2)} CZK
                            </p>
                        </div>
                        <div className="text-center">
                            <h2 className="text-base md:text-lg font-semibold text-gray-100">📈 Avg Orders/Day (All
                                Time)</h2>
                            <p className="text-xl md:text-2xl font-bold text-[#9f7aea]">{stats.avgOrdersPerDay}</p>
                        </div>
                    </div>
                </div>

                {mainTab === "current" && (
                    <div className="mb-2 md:mb-4 flex flex-col space-y-2">
                        <label className="font-semibold text-gray-100 text-sm md:text-base" htmlFor="timeframe-select">
                            Select Timeframe:
                        </label>
                        <select
                            id="timeframe-select"
                            value={timeFrame}
                            onChange={(e) => setTimeFrame(e.target.value)}
                            className="border border-gray-700 p-2 rounded-lg w-full bg-gray-800 text-gray-100 text-sm md:text-base"
                        >
                            <option value="today">🌤️ Today</option>
                            <option value="yesterday">🌒 Yesterday</option>
                            <option value="thisMonth">🗓️ This Month</option>
                            <option value="thisYear">📆 This Year</option>
                            <option value="allTime">🌎 All Time</option>
                        </select>
                    </div>
                )}

                {mainTab === "history" && (
                    <div className="mb-2 md:mb-4 flex flex-col space-y-2">
                        <label className="font-semibold text-gray-100 text-sm md:text-base">Select Date Range:</label>
                        <div className="flex flex-row space-y-0 space-x-4">
                            <input
                                type="date"
                                className="border border-gray-700 p-2 rounded-lg bg-gray-800 text-gray-100 text-sm md:text-base"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <input
                                type="date"
                                className="border border-gray-700 p-2 rounded-lg bg-gray-800 text-gray-100 text-sm md:text-base"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                )}

                {/* Tabs for Orders vs Sales */}
                <div className="mb-2 md:mb-4 flex space-x-2 md:space-x-4">
                    <button
                        onClick={() => setViewMode("czk")}
                        className={`py-1 px-2 md:py-2 md:px-4 rounded text-sm ${viewMode === "czk" ? "bg-blue-600 text-white" : "bg-gray-700 text-gray-200"}`}
                    >
                        Sales (CZK)
                    </button>
                    <button
                        onClick={() => setViewMode("orders")}
                        className={`py-1 px-2 md:py-2 md:px-4 rounded text-sm ${viewMode === "orders" ? "bg-blue-600 text-white" : "bg-gray-700 text-gray-200"}`}
                    >
                        Orders
                    </button>
                </div>

                <div className="p-2 md:p-4 rounded-lg bg-gray-800">
                    <h2 className="text-lg md:text-xl font-bold mb-2 md:mb-4 text-gray-100">
                        {getOverviewTitle()}
                    </h2>
                    <div className="w-full" style={{height: 'auto'}}>
                        <ResponsiveContainer width="100%" height={window.innerWidth < 768 ? 250 : 400}>
                            <LineChart
                                data={chartData}
                                margin={{top: 5, right: 20, left: 10, bottom: 5}}
                            >
                                <CartesianGrid strokeDasharray="3 3" stroke="#444"/>
                                <XAxis dataKey="date" stroke="#bbb" style={{fontSize: '0.75rem'}}/>
                                <YAxis stroke="#bbb" style={{fontSize: '0.75rem'}}/>
                                <Tooltip
                                    contentStyle={{backgroundColor: '#2a2a2a', border: '1px solid #444', color: '#FFF'}}
                                    itemStyle={{color: '#FFF', fontSize: '0.75rem'}}
                                    labelStyle={{color: '#FFF', fontSize: '0.75rem'}}
                                    formatter={(value, name) => {
                                        const label = name === "czk" ? "CZK" : "Orders";
                                        return [Math.round(value), label];
                                    }}
                                    labelFormatter={(label) => `At ${label}:`}
                                />
                                <Legend
                                    formatter={(value) => (value === "czk" ? "CZK" : "Orders")}
                                    wrapperStyle={{color: '#ccc', fontSize: '0.75rem'}}
                                />
                                {viewMode === "orders" && (
                                    <Line
                                        type="monotone"
                                        dataKey="orders"
                                        stroke="#9f7aea"
                                        strokeWidth={2}
                                        activeDot={{r: 8}}
                                    />
                                )}
                                {viewMode === "czk" && (
                                    <Line
                                        type="monotone"
                                        dataKey="czk"
                                        stroke="#48bb78"
                                        strokeWidth={2}
                                        activeDot={{r: 8}}
                                    />
                                )}
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderStats;
