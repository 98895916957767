import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import {LoginContext} from "../Contexts/LoginContextProvider";

function CreateProduct({title}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${title} - Admin Panel`;
        return () => {
            document.title = "Admin Panel";
        };
    }, [title]);

    const [product, setProduct] = useState({
        title: "",
        price: 0,
        stock: 0,
        desc: "",
        img: "",
        storeId: 2,
        product_handle: "",
    });
    const navigate = useNavigate();
    const { getToken, loginState } = useContext(LoginContext);
    const token = getToken();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct((prev) => ({ ...prev, [name]: value }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("image", file);

        fetch("https://v2.whustle.com/products/upload-image", {
            method: "POST",
            body: formData,
            headers: {
                'Authorization': token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                setProduct((prev) => ({ ...prev, img: data.img_url }));
            })
            .catch((error) => console.error("Error uploading image:", error));
    };

    const handleSubmit = () => {
        fetch("https://v2.whustle.com/products", {
            method: "POST",
            body: JSON.stringify(product),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }}
        )
            .then(() => {
                navigate("/list");
            })
            .catch((error) => console.error("Error creating product:", error));
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-gray-100 min-h-screen overflow-hidden">
            <Navbar />
            <div className="p-4 md:p-8 mx-auto md:w-2/3 w-full">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-100 mb-6">
                    Create New Product
                </h1>

                <div className="bg-gray-800 rounded-lg shadow p-6">
                    {/* Form */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Title */}
                        <div>
                            <label className="block text-gray-300 font-medium mb-2">
                                Title
                            </label>
                            <input
                                type="text"
                                name="title"
                                value={product.title}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        {/* Price */}
                        <div>
                            <label className="block text-gray-300 font-medium mb-2">
                                Price
                            </label>
                            <input
                                type="number"
                                name="price"
                                value={product.price}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        {/* Stock */}
                        <div>
                            <label className="block text-gray-300 font-medium mb-2">
                                Stock
                            </label>
                            <input
                                type="number"
                                name="stock"
                                value={product.stock}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        {/* Product Handle */}
                        <div>
                            <label className="block text-gray-300 font-medium mb-2">
                                Product Handle
                            </label>
                            <input
                                type="text"
                                name="product_handle"
                                value={product.product_handle}
                                onChange={handleInputChange}
                                className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>

                    {/* Description */}
                    <div className="mt-6">
                        <label className="block text-gray-300 font-medium mb-2">
                            Description
                        </label>
                        <textarea
                            name="desc"
                            value={product.desc}
                            onChange={handleInputChange}
                            className="w-full border border-gray-700 bg-gray-900 text-gray-100 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            rows="4"
                        />
                    </div>

                    {/* Image Upload */}
                    <div className="mt-6">
                        <label className="block text-gray-300 font-medium mb-2">
                            Product Image
                        </label>
                        <input
                            type="file"
                            onChange={handleImageChange}
                            className="block w-full text-gray-300"
                        />
                        {product.img && (
                            <img
                                className="w-32 h-32 mt-4 rounded-lg object-cover"
                                src={product.img}
                                alt="Product"
                            />
                        )}
                    </div>

                    {/* Submit Button */}
                    <button
                        onClick={handleSubmit}
                        className="bg-green-600 hover:bg-green-700 text-white font-medium rounded-lg px-4 py-2 mt-6"
                    >
                        Create Product
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateProduct;
